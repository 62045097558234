import { ChatThreadDetail } from '../components/AIChat/Admin/Home';

function AIChatThreadDetailRoute() {
  return <ChatThreadDetail />;
}

export const Component = AIChatThreadDetailRoute;

export function clientLoader() {
  return null;
}
